import { createGlobalStyle } from 'styled-components';

import animations from '../styles/animations';
import global from '../styles/global';
import reset from '../styles/reset';
import typography from '../styles/typography';
import utils from '../styles/utils';

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${typography}
  ${global}
  ${utils}
  ${animations}
  ${(props) => props.theme.FontFaces}
  ${(props) => props.theme.fonts}
`;

export default GlobalStyle;
