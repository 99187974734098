import { css } from 'styled-components';

import { Breakpoints } from '@powdr/constants';
// TODO: Get rid of as many styles like this as possible

const utils = css`
  /************************/
/* Utils */
/************************/
/*
.u-b-l {
  border-left: 1px solid color(shades, light);
}

.u-b-b {
  border-bottom: 1px solid color(shades, light);
} */

.u-noscroll,
.u-noscroll-levels {
  overflow: hidden;
}


.u-center-block {
  text-align: center;
  li.l-item {
    display: inline-block !important;
  }
}

.u-hide {
  display: none !important;
}

/*.u-fixed {*/
/*position: fixed;*/
/*z-index: 100;*/
/*}*/

.u-squeeze-20p {
  padding-left: 20%;
  padding-right: 20%;

  /* @include bp-smallest {
    &.m-bp-smallest {
      padding-left: 20px;
      padding-right: 20px;
    }
  } */
}

.u-squeeze-10p {
  padding-left: 10%;
  padding-right: 10%;
}

.u-squeeze-5p {
  padding-left: 5%;
  padding-right: 5%;
}

.u-fluid {
  display: block !important;
  width: 100% !important;
  height: auto !important;
}

.u-inline-group-inputs {
  input {
    display: inline-block;
  }
}

.u-inline {
  display: inline-block !important;
}

.u-block {
  display: block !important;
}

.u-v-middle {
  vertical-align: middle;
}

.u-block-group-inputs {
  input {
    display: block;
  }
}

.u-txt-shadow {
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
}

.u-next-line {
  white-space: normal !important;
}

/* $floats: "left", "right";

@for $align from 1 through length($floats) {
  $val: nth($floats, $align);

  .u-pull-absolute-#{$val} {
    position: absolute;
    #{$val}: 0;
  }

  .u-pull-#{$val} {
    float: #{$val} !important;
  }
} */

/* $textTransform: "uppercase", "lowercase", "capitalize";

@for $txt from 1 through length($textTransform) {
  $val: nth($textTransform, $txt);

  .u-txt-#{$val} {
    text-transform: #{$val} !important;
  }
}

$textAlign: "center", "left", "justify", "right", "inherit";

@for $txt from 1 through length($textAlign) {
  $val: nth($textAlign, $txt);

  .u-txt-align-#{$val} {
    text-align: #{$val} !important;
  }
} */

// TODO: Pretty sure these are here so that client can use them embed stuff inside of WSYIWYG components,
// we may be able to move these to those components individually rather than having them here
.u-embed-container,
.embed-container,
.embed-container-smaller {
  position: relative;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
  aspect-ratio: 16 / 9;

  embed,
  iframe,
  object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.embed-container-smaller {
  ${Breakpoints.desktopOnly(css`
    max-width: 75%;
  `)}
}
`;

export default utils;
