import { keyframes, css } from 'styled-components';

const animationDelays = () => {
  let styles = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 5; i++) {
    styles += `
      .u-an-de-${i} {
        animation-delay: ${i * 0.25}s !important;
      }
    `;
  }
  return css`${styles}`;
};

const bob = keyframes`
  0% { transform: translateY(-8px);}
  50% {transform: translateY(-4px);}
  100% {transform: translateY(-8px);}
`;

const bobFloat = keyframes`
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
`;

const fadeInUp = keyframes`
 from {
    opacity: 0;
    transform: translate3d(0, 25px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

const fadeOutDown = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 25px, 0);
  }
`;

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

const bounce = keyframes`
  from {
    transform: translate3d(0, -25px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const animations = css`
  .animated {
    animation-duration: 0.6s;
    animation-fill-mode: both;
  }

  .fadeInUp {
    animation-name: ${fadeInUp};
  }

  .fadeOutDown {
    animation-name: ${fadeOutDown};
  }

  .fadeInDown {
    animation-name: ${fadeInDown};
  }

  .bounce {
    animation-name: ${bounce} !important;
    animation-duration: 0.5s !important;
    animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5) !important;
    animation-delay: 0s !important;
    animation-iteration-count: 5 !important;
    animation-direction: alternate !important;
  }

  .anim-bob {
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);

    &:hover, &:focus, &:active {
      animation-name: ${bobFloat}, ${bob};
      animation-duration: .3s, 1.5s;
      animation-delay: 0s, .3s;
      animation-timing-function: ease-out, ease-in-out;
      animation-iteration-count: 1, infinite;
      animation-fill-mode: forwards;
      animation-direction: normal, alternate;
    }
  }

  .anim-float {
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;

    &:hover, &:focus, &:active {
      transform: translateY(-8px);
    }
  }

  ${animationDelays}
`;

export default animations;
