/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-unresolved
import { css } from 'styled-components';

import { FontFamily } from '@powdr/constants';

const typography = css`
  body {
    ${({ theme }) => `
      font-family: ${theme.fonts[FontFamily.BASE_FONT].fontFamily};
      text-transform: ${theme.fonts[FontFamily.BASE_FONT].textTransform};
      font-size: ${theme.fonts[FontFamily.BASE_FONT].defaultSize};
    `};
  }

  h1, h2, h3, h4, h5, h6 {
    ${({ theme }) => `
      font-family: ${theme.fonts[FontFamily.PRIMARY_FONT].fontFamily};
      text-transform: ${theme.fonts[FontFamily.PRIMARY_FONT].textTransform};
      font-size: ${theme.fonts[FontFamily.PRIMARY_FONT].defaultSize};
    `};
  }

  button {
    ${({ theme }) => `
      font-family: ${theme.fonts[FontFamily.SECONDARY_FONT].fontFamily};
      text-transform: ${theme.fonts[FontFamily.SECONDARY_FONT].textTransform};
      font-size: ${theme.fonts[FontFamily.SECONDARY_FONT].defaultSize};
    `};
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

`;

export default typography;
