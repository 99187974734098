import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, {
  useContext,
} from 'react';
import styled from 'styled-components';

import { TvPicker } from '@powdr/components';
import { ThemeProps as Prop, StaticProfiles } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { themeUtil } from '@powdr/utils';

import GlobalStyle from '../utils/global-styles';

const SiteWrapper = styled.div`
  height: 100vh;
  background-color: ${() => themeUtil(Prop.BACKGROUND, null, StaticProfiles.TV)};
`;

function TvHome({
  data,
}) {
  const { defaultTheme } = useContext(AppContext);
  const tvs = data?.tvHomeSettings?.relationships?.tvs || null;

  if (!tvs) return null;

  return (
    <>
      <GlobalStyle theme={defaultTheme} />
      <SiteWrapper>
        <TvPicker tvs={tvs} />
      </SiteWrapper>
    </>
  );
}

TvHome.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default TvHome;

export const query = graphql`
  query TvHome {
    site {
      ...SiteMetadataQuery
    }
    tvHomeSettings: taxonomyTermTvNavigation( field_navigation_link: { eq: "/tv" } ) {
      relationships {
        tvs: taxonomy_term__tv_navigation {
          ...TvPageQuery
        }
      }
    }
  }
`;
